const formatGraphDate = (str) => {
	const x = new Date(str);
	const date = x.getDate();
	const readableMonth = x.toLocaleDateString("sv", { month: "numeric" });

	return (
		<div>
			{date}/{readableMonth}
		</div>
	);
};

const Graph = ({ data, levels }) => {
	const max = Math.max(...levels);

	return (
		<ol key="scoring" className="scoring">
			<li className="diagnostic-level-wrapper">
				<div className="diagnostic-level">
					{levels.map((level) => (
						<span
							key={level}
							className="diagnostic-level__item"
							style={{ "--l": level / max }}
						>
							<span className="diagnostic-level__item-number">
								{level}
							</span>
						</span>
					))}
				</div>
			</li>
			{data.map(({ date, total }, i) => (
				<li key={i} className="graph">
					<div
						key="bar"
						style={{ "--delta": total / max }}
						className="graph__bar"
						data-score={total}
					>
						<span className="graph__score">{total}</span>
					</div>
					<time dateTime={date} className="graph__date">
						{formatGraphDate(date)}
					</time>
				</li>
			))}
		</ol>
	);
};

export default Graph;
