import { useMemo } from "react";
import useAPI from "../../hooks/useAPI";
import { shortDateFormat, formatDate } from "../../utils";
import { EditButtonXS } from "../Template/Button";
import { PATH_WEIGHT } from "../../endpointConfig";
import Loader from "../Loader";
import CalendarNav from "../Calendar/CalendarNav";
import useCalendar from "../Calendar/useCalendar";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";

function addWeightChange(weight) {
	return weight.map((row, i) => {
		const nextRow = weight[i + 1] || row;
		const weightDelta = row.amount - nextRow.amount;

		return { ...row, change: weightDelta };
	});
}

function calculateBMI(weight) {
	const heightInMeters = 1.6;
	const heightSquare = Math.pow(heightInMeters, 2);
	const bmiResult = weight / heightSquare;

	return bmiResult.toFixed(2);
}

const WeightCalendar = () => {
	const { value: weight, loading } = useAPI("gravitas", {
		handler: addWeightChange,
	});
	const dates = weight.map((x) => new Date(x.date));
	const calendar = useCalendar(dates);
	const date = calendar.date;

	const filteredWeigth = useMemo(() => {
		const yymm = formatDate(date).substr(0, 7);

		return weight.filter((x) => x.date.substr(0, 7) === yymm);
	}, [date, weight]);

	return (
		<section className="section section--weight">
			<h1 className="section__title">Vikt-tracking</h1>
			<div className="calendar">
				<header className="calendar__nav">
					<CalendarNav calendar={calendar} />
				</header>
				{loading ? (
					<Loader />
				) : filteredWeigth.length > 0 ? (
					<>
						<ul className="datalist weightlist">
							{filteredWeigth.map(
								({ id, date, amount, note, change }) => {
									const direction =
										change > 0
											? "up"
											: change < 0
											? "down"
											: "idle";

									return (
										<li
											key={id}
											className="datalist__item weightlist__item"
										>
											<h2 className="weightlist__date">
												{shortDateFormat(date)}
											</h2>
											{/* prettier-ignore */}
											<span className={`weightlist__amount weightlist__amount--${direction}`}>
												{amount} kg
											</span>
											<span className="weightlist__bmi">
												BMI: {calculateBMI(amount)}
											</span>
											{!note ? null : (
												<p className="weightlist__note">
													Anm: {note}
												</p>
											)}
											<EditButtonXS
												id={id}
												segment={PATH_WEIGHT}
											/>
										</li>
									);
								}
							)}
						</ul>
						<section className="section-bmi-info">
							<h2>BMI skalor</h2>
							<ul>
								<li>Under 20 &ndash; Undervikt</li>
								<li>20- 25 &ndash; Normalvikt</li>
								<li>25-30 &ndash; Övervikt</li>
								<li>30-35 &ndash; Fetma</li>
								<li>över 35 &ndash; Kraftig fetma</li>
							</ul>
						</section>
					</>
				) : (
					<DisplayNoDataMessage classes="calendar-presentation__msg" />
				)}
			</div>
		</section>
	);
};

export default WeightCalendar;
