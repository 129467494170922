import { useState, useEffect, useRef } from "react";
import { useLocation, Link, useResolvedPath } from "react-router-dom";
import {
	goHome,
	goOut,
	goToLogs,
	goToSurveys,
	PATH_CYCLES,
	PATH_DRUG,
	PATH_HURT,
	PATH_MEDS,
	PATH_MOOD,
	PATH_POS,
	PATH_SICKNESS,
	PATH_SURVEY,
	PATH_WEIGHT,
	PATH_V,
} from "../endpointConfig";
import useOutsideClick from "../hooks/useOutsideClick";

const MenuLink = ({ url, label, modifier }) => {
	const currentPath = useLocation().pathname.toLowerCase();
	const toPath = useResolvedPath(url).pathname.toLowerCase();
	const isSelected = currentPath === toPath;

	return (
		<li className="nav__list-item">
			<Link
				to={url}
				className={`nav__item-link ${
					modifier ? `nav__item-link--${modifier}` : ""
				} ${isSelected ? "is-selected" : ""}`}
			>
				{label}
			</Link>
		</li>
	);
};

const MenuListSection = ({ children, title }) => {
	return (
		<section className="nav__section-list">
			<h3 className="nav__list-title">{title}</h3>
			<ul className="nav__list">{children}</ul>
		</section>
	);
};

const Menu = () => {
	const [openMenu, setOpenMenu] = useState(false);
	const { pathname } = useLocation();
	const menuRef = useRef(null);
	const menuClasses = `nav ${openMenu ? "is-open" : ""}`.trim();
	const classes = `nav__main ${openMenu ? "is-open" : ""}`.trim();

	useEffect(() => {
		setOpenMenu(false);
	}, [pathname]);

	useOutsideClick(menuRef, () => {
		if (openMenu) setOpenMenu(false);
	});

	const toggle = () => {
		requestAnimationFrame(() => {
			setOpenMenu(!openMenu);
		});
	};

	return (
		<nav className={menuClasses} ref={menuRef}>
			<button className="nav__btn" type="button" onClick={toggle}>
				{openMenu ? "Stäng" : "Meny"}
			</button>
			<div className={classes}>
				<div className="nav__inner">
					<h2 className="nav__head-title">Hälsoappen</h2>
					<ul className="nav__list nav__list--top">
						<MenuLink url={goToLogs} label="Journal" />
						<MenuLink url={goToSurveys} label="Enkät" />
					</ul>

					<MenuListSection title="Formulär - Daily">
						<MenuLink url={`/${PATH_MEDS}/add`} label="Dosett" />
						<MenuLink url={`/${PATH_MOOD}/add`} label="Humör" />
						<MenuLink
							url={`/${PATH_POS}/add`}
							label="Positiva händelser"
						/>
						<MenuLink url={`/${PATH_WEIGHT}/add`} label="Vikt" />
					</MenuListSection>

					<MenuListSection title="Formulär - Surveys">
						<MenuLink url={`/${PATH_SURVEY}/gad7`} label="GAD-7" />
						<MenuLink url={`/${PATH_SURVEY}/phq9`} label="PHQ-9" />
						<MenuLink url={`/${PATH_SURVEY}/pcl5`} label="PCL-5" />
					</MenuListSection>

					<MenuListSection title="Formulär- Övrigt">
						<MenuLink url={`/${PATH_HURT}/add`} label="Skador" />
						<MenuLink url={`/${PATH_V}/add`} label="Myspys" />
						<MenuLink url={`/${PATH_DRUG}/add`} label="Medicin" />
						<MenuLink
							url={`/${PATH_SICKNESS}/add`}
							label="Sjukdom"
						/>
						<MenuLink
							url={`/${PATH_CYCLES}/add`}
							label="Menscykel"
						/>
					</MenuListSection>

					<footer className="nav__list nav__list--bottom">
						<MenuLink
							url={goHome}
							label="Start"
							modifier="bottom"
						/>
						<span aria-label="Avskiljare">&bull;</span>
						<MenuLink
							url={goOut}
							label="Logga ut"
							modifier="bottom"
						/>
					</footer>
				</div>
			</div>
		</nav>
	);
};

export default Menu;
